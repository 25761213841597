export const isElectron = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf(' electron/') !== -1;
};
export const getPageRange = (current,itemsPerPage) => {
  const start = (current - 1) * itemsPerPage + 1;
  const end = current * itemsPerPage;
  return `${start}-${end}`;
};
export function capitalizeFirstLetter(word) {
  return word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase():undefined;
}