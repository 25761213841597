import React from "react"
import //ReactDOM ,
{createRoot }from "react-dom/client"
//css
import "./index.css"
//App
import App from "./App"
//Service Worker
import * as serviceWorker from "./serviceWorker"

//Ant Design
// import 'antd/dist/antd.min.css';
//Redux
import { Provider } from "react-redux"
import configureStore from "./Redux/configureStore"

const store = configureStore()
const rootElement = document.getElementById("root");

const root = createRoot(rootElement);
root.render(
	<Provider store={store}>
		<App />
	</Provider>
	// ,
	// document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
