const initialState = {
	user: [],
}

export default function userReducer(state = initialState, action) {

	switch (action.type) {
		case "SET_PROFILE":
			return {
				user: action.user,
			}
		default:
			return state
	}
}
