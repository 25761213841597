//It is used to protect from un authorized user
import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { APIRequest } from "../Utils/APIManager";

/* Private route for authenticate (component or page) */
export const PrivateRoute = ({ component: Component, ...rest }) => {
  const [status, setStatus] = useState(false)
  const getValidationToken=async ()=>{
    try {
      const isValidToken=await APIRequest.getGetService("/users/verify_token")
      if (isValidToken.status) {
        setStatus(true);
      }else{
        setStatus(false);
        localStorage.removeItem("agarathi_token");
      }
    } catch (error) {
      console.log(error);
      setStatus(false);
      localStorage.removeItem("agarathi_token");
    }
  }


  useEffect(() => {
    getValidationToken();
  }, [])
  


  return (
    <Route
      {...rest}
      render={(props) => {
        /* Authenticate user by auth token */
        const authToken = localStorage.getItem("agarathi_token");
        if (
          authToken === null ||
          authToken === "null" ||
          authToken === undefined
        ) {
          /* not logged in so redirect to login page with the return url */
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
        return (
          <>
            <Component {...props} />
          </>
        );
      }}
    />
  )
};

export default PrivateRoute;
