import { combineReducers } from 'redux'

import LanguageReducer from './LanguageReducer'
import SavedReducer from './SavedReducer'
import LoadingReducer from './LoadingReducer'
import SearchReducer from './SearchReducer'
import MicReducer from './MicReducer'
import UserReducer from './userReducer'

const rootReducer = combineReducers({
    LanguageReducer,SavedReducer,LoadingReducer,SearchReducer,MicReducer,UserReducer
})

export default rootReducer