const initialState = {
	searchResultNotFound: false,
}

export default function SearchReducer(state = initialState, action) {

	switch (action.type) {
		case "SEARCHNOTFOUND":
			return {
				searchResultNotFound: action.payload.searchNotFound,
			}
		default:
			return state
	}
}
