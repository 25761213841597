const initialState = {
	loading: false,
}

export default function LoadingReducer(state = initialState, action) {
	switch (action.type) {
		case "LOADING":
           return {
                loading: action.loading,
			}
		case "LOADING_OFF":
           return {
                loading: false,
			}
		default:
			return state
	}
}
